import { Image } from 'react-bootstrap';
import LoaderImg from '../../images/ajax.gif';

const Loader = () => {
  return (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      <Image src={LoaderImg} alt="Loader" roundedCircle />
    </div>
  );
};

export default Loader;
