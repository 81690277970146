import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as Error500Icon } from '../images/coffee_spoil.svg';

const Error500Page = () => {
  return (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center flex-column">
      <Error500Icon />
      <h2 className="text text--dark-blue mb-2 heading heading--lg">
        Something went wrong
      </h2>
      <Button
        variant="warning"
        as={Link}
        to="/"
        className="ml-auto mr-auto mt-2 "
      >
        &#x2190; Back to homepage
      </Button>
    </div>
  );
};

export default Error500Page;
