import React from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import authService from '../services/authService';
import { useStateValue } from '../context';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import SubmitButton from '../components/common/SubmitButton';
import { actionTypes } from '../context/reducer';
import swal from 'sweetalert';
const Stripe = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [state, dispatch] = useStateValue();
  const [isStripeError, setisStripeError] = useState({
    isError: false,
    message: '',
    type: null,
  });
  const [UserName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({
    isError: false,
    message: '',
    type: 'danger',
  });

  const { mutateAsync } = useMutation(
    ['stripe-payment'],
    authService.stripPayment,
    {
      onSuccess: ({ message }) => {
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'success' },
        });
        setIsLoading(false);
        setApiError({
          isError: true,
          message,
          type: 'success',
        });
        queryClient.invalidateQueries('get-credit');
      },
      onError: (error) => {
        setIsLoading(false);
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
        setApiError({
          isError: true,
          message,
          type: 'danger',
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const handleChange = (e) => {
    setApiError({
      isError: false,
      message: '',
      type: 'danger',
    });
    if (e?.error?.message) {
      setisStripeError({ isError: true, message: e?.error?.message });
    } else {
      setisStripeError({ isError: false, message: '' });
    }
  };

  const handlePayment = async (token) => {
    setIsLoading(true);
    const payload = {
      amount: location?.state?.amount,
      payment_token: token,
      type: 'stripe',
    };
    await mutateAsync(payload);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (UserName === '') {
      setisStripeError({
        isError: true,
        message: 'Please enter name.',
        type: 'custom',
      });
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(cardElement, {
      name: UserName ? UserName : '',
    });
    if (!error) {
      handlePayment(token?.id);
    } else {
      swal({
        type: 'danger',
        text: error?.message,
        icon: 'error',
        timer: 3000,
      });
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 paymentForm mt-3 w-max-500 mx-auto"
    >
      {isStripeError?.message ? (
        <Alert key="error" variant="danger">
          {isStripeError?.message ?? ''}
        </Alert>
      ) : null}
      {apiError?.message ? (
        <Alert key="api-res" variant={apiError?.type}>
          {apiError?.message ?? ''}
        </Alert>
      ) : null}

      <div className="mb-3 d-flex gap-4">
        {' '}
        <input
          type="text"
          name="full_name"
          className={`form-control  ${
            isStripeError?.type ? 'form-control__error' : ''
          }`}
          placeholder="Name"
          onChange={(e) => {
            setUserName(e.target.value);
            setisStripeError({ isError: false, message: '', type: null });
            setApiError({
              isError: false,
              message: '',
              type: 'danger',
            });
          }}
          maxLength="15"
        />
      </div>
      <div className="mb-3 d-flex gap-4">
        <CardNumberElement
          onChange={(e) => handleChange(e)}
          className="form-control "
          options={{
            showIcon: true,
          }}
        />
      </div>
      <div className="mb-3 d-flex gap-4">
        <div className="w-100">
          <CardExpiryElement
            onChange={(e) => handleChange(e)}
            className="form-control "
          />
        </div>
        <div className="w-100">
          {' '}
          <CardCvcElement
            onChange={(e) => handleChange(e)}
            className="form-control "
          />
        </div>
      </div>

      <SubmitButton
        className="w-100"
        variant="warning"
        type="submit"
        isLoading={isLoading}
        label={`Pay ${location?.state?.amount}`}
      />
    </form>
  );
};

export default Stripe;
