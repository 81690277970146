import { post, getApiUrl } from './config';

const login = (credentials) =>
  post({
    url: getApiUrl(`api/login`),
    payload: { ...credentials, deviceToken: '', device_type: 'WEB' },
  });
const SocialLogin = (credentials) =>
  post({
    url: getApiUrl(`api/social-login`),
    payload: { ...credentials, device_type: 'WEB' },
  });

const checkUser = (inputs) =>
  post({
    url: getApiUrl(`api/check-user`),
    payload: inputs,
  });

const stripPayment = (inputs) =>
  post({
    url: getApiUrl(`api/add-topup`),
    payload: inputs,
  });

const confirmOtp = (inputs) =>
  post({
    url: getApiUrl(`api/confirm-otp`),
    payload: inputs,
  });

const resendOtp = (inputs) =>
  post({
    url: getApiUrl(`api/send-otp`),
    payload: inputs,
  });

const forgotPassword = (inputs) =>
  post({
    url: getApiUrl(`api/forgot-password`),
    payload: inputs,
  });

const register = (inputs) =>
  post({
    url: getApiUrl(`api/register`),
    payload: inputs,
  });

const resetPassword = (inputs) =>
  post({
    url: getApiUrl(`api/reset-password`),
    payload: inputs,
  });

const refreshToken = (refresh_token) =>
  post({
    url: getApiUrl('api/refresh'),
    payload: refresh_token,
  });

const deleteAccount = () =>
  post({
    url: getApiUrl('api/delete-profile'),
  });

const loginService = {
  SocialLogin,
  login,
  checkUser,
  confirmOtp,
  resendOtp,
  register,
  forgotPassword,
  resetPassword,
  refreshToken,
  deleteAccount,
  stripPayment,
};

export default loginService;
