import axios from 'axios';

const getAccessToken = () => localStorage.access;
const mode = window?.location?.hostname?.split('-')?.[0];
export const apiEndpointURL = process.env.REACT_APP_API_BASE_URL;
// export const apiEndpointURL = import.meta.env.VITE_API_BASE_URL;

export const getApiUrl = (path) => `${apiEndpointURL}/${path}`;

const pullData = (request) => request.then(({ data }) => data);

export const get = ({ url }) =>
  pullData(
    axios.get(url, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }),
  );
export const post = ({ url, payload }) =>
  pullData(
    axios.post(url, payload, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }),
  );
export const uploadPost = ({ url, payload }) =>
  pullData(
    axios.post(url, payload, {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }),
  );

export const patch = ({ url, payload }) =>
  pullData(
    axios.patch(url, payload, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }),
  );

export const put = ({ url, payload }) =>
  pullData(
    axios.put(url, payload, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }),
  );

export const remove = ({ url }) =>
  pullData(
    axios.delete(url, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }),
  );

export const apiClient = axios.create({
  baseURL: apiEndpointURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = null;
    if (token) {
      config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);
