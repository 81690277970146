import React from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as ArrowIconLeft } from '../images/arrow-left.svg';
import { useNavigate, useParams } from 'react-router-dom';
import Paypal from './Paypal';
import Stripe from './Stripe';

const TopupType = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div className="account w-max-500 mx-auto">
      <span
        className=" prev-month font-18 d-flex align-items-center pointer"
        onClick={() => navigate('/account', { replace: true })}
      >
        <ArrowIconLeft /> <span className="ml-2 ">Back</span>
      </span>
      <Container>
        {params?.type === 'international' && <Stripe />}
        {params?.type === 'paypal' && <Paypal />}
        {/* {params?.type === 'mtn' && <Stripe />}
        {params?.type === 'moov' && <Stripe />} */}
      </Container>
    </div>
  );
};

export default TopupType;
