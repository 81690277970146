import { Outlet } from 'react-router-dom';
import AppHeader from './AppHeader';
// import AppFooter from './AppFooter';

const Main = () => {
  return (
    <>
      <AppHeader />
      <div className="mid-content">
        <Outlet />
      </div>
      {/* <AppFooter /> */}
    </>
  );
};

export default Main;
