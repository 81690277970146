import { Toast, ToastContainer } from 'react-bootstrap';
import { useStateValue } from '../../context';
import { actionTypes } from '../../context/reducer';

const Toaster = () => {
  const [{ showToast }, dispatch] = useStateValue();

  const handleToastClose = () => () =>
    dispatch({
      type: actionTypes.SET_SHOW_TOAST,
      value: { status: false, message: '', msgType: 'default' },
    });

  return (
    <ToastContainer
      position="top-center"
      className="p-3"
      style={{ zIndex: 20 }}
    >
      <Toast
        onClose={handleToastClose()}
        show={showToast.status}
        delay={6000}
        autohide
        bg={showToast.msgType}
      >
        <Toast.Header>
          <strong className="me-auto">
            {showToast.msgType === 'danger' ? 'Error' : showToast.msgType}
          </strong>
          {/* <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body className="text-white">{showToast.message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Toaster;
