import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useStateValue } from '../context';
import { actionTypes } from '../context/reducer';
import historyService from '../services/historyService';
import swal from 'sweetalert';
import { addressProofType, proofTypeID } from '../services/apiRoutes';
import { Country } from 'country-state-city';


const initialValues = {
  name: '',
  country: '',
  city: '',
  did: '',
  period: '',
  picture: '',
  state: '',
  profileType: {
    type: '',
    first_name: '',
    last_name: '',
    user_city: '',
    user_country: '',
    company_name: '',
    address: '',
    registration_num: '',
    postal_code: '',
    address_proof: '',
    address_proof_type_id: '',
    proof_type_id: '',
    vat_id: '',
    personal_tax_id: '',
    proof: '',
  },
};



const renderConditionalValidations = (name, checkArray, fieldName) => {
  return yup.string().when(name, {
    is: (value) => checkArray?.includes(value),
    then: yup
      .string()
      .required(`${fieldName} is required`),
  })
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  country: yup.string().required('Country is required'),
  did: yup.string().required('Did is required'),
  period: yup.string().required('Period is required'),
  profileType: yup.object().shape({
    first_name: renderConditionalValidations('type', ['Business', 'Personal'], 'First Name'),
    last_name: renderConditionalValidations('type', ['Business', 'Personal'], 'Last Name'),
    company_name: renderConditionalValidations('type', ['Business'], 'Company Name'),
    address: renderConditionalValidations('type', ['Business', 'Personal'], 'Address'),
    user_city: renderConditionalValidations('type', ['Business', 'Personal'], 'User City'),
    user_country: renderConditionalValidations('type', ['Business', 'Personal'], 'User Country'),
    registration_num: renderConditionalValidations('type', ['Business'], 'Company Registration Number'),
    vat_id: renderConditionalValidations('type', ['Business'], 'VAT Number'),
    personal_tax_id: renderConditionalValidations('type', ['Business'], 'TAX Number'),
    postal_code: renderConditionalValidations('type', ['Business', 'Personal'], 'Postal Code'),
    address_proof_type_id: renderConditionalValidations('type', ['Business', 'Personal'], 'Address Proof Type'),
    proof_type_id: renderConditionalValidations('type', ['Business', 'Personal'], 'Proof Type'),
    address_proof: renderConditionalValidations('type', ['Business', 'Personal'], 'Address Proof'),
    proof: renderConditionalValidations('type', ['Business', 'Personal'], 'Proof'),
  })
});

const ProfileAdd = () => {
  let user = localStorage?.userDetails
    ? JSON.parse(localStorage?.userDetails)
    : {};

  const [, dispatch] = useStateValue();
  const countriesArr = ['FR', 'UA', 'PL', 'SE', 'BE', 'GB'];
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [particularDid, setparticularDid] = useState({});
  const [paymentType, setPaymentMethod] = useState('wallet');
  const [cityLists, setCityLists] = useState([]);
  const [allDidData, setDidData] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [filePreviewProof, setFilePreviewProof] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorProfileType, setIsErrorProfileType] = useState(false)

  const addressProofRef = useRef(null)
  const proofRef = useRef(null)

  function handleReset() {
    setCityLists([]);
    setDidData([]);
    setparticularDid({});
  }



  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmitForm,
  });
  const typeOfProfile = formik?.values?.profileType?.type;
  const selectedCountryISO = formik.values.country?.split('_')?.[1];

  const { data: dataAddressProofType } = useQuery(
    'address-proof-type',
    addressProofType,
    {
      select: (data) => data?.data?.data,
    },
  );

  const { data: dataProofTypeID } = useQuery(
    ['address-proof-type', typeOfProfile],
    () =>
      proofTypeID({
        type: typeOfProfile,
      }),
    {
      select: (data) => data?.data?.data,
      enabled: Boolean(typeOfProfile),
    },
  );



  const {
    isLoading: isLoadingPaymentToken,
    mutateAsync: mutateAsyncPaymentToken,
  } = useMutation(['paymentToken'], historyService.paymentToken, {
    onSuccess: (data) => {
      window.open(
        `${process.env.REACT_APP_PAYMENT_BASE_URL}?token=${data?.data?.token}&msisdn=${data?.data?.msisdn}&currency=${data?.data?.currency}&amount=${data?.data?.amount}&country=${user?.country}&origin=web`,
        '_self',
      );
    },
    onError: (error) => {
      let message = error.message;
      if (error.response?.data) message = error.response.data.message;
      dispatch({
        type: actionTypes.SET_SHOW_TOAST,
        value: { status: true, message, msgType: 'danger' },
      });
    },
  });
  const { isLoading: isLoadingMedia, mutateAsync: mutateAsyncUploadMedia } =
    useMutation(['paymentToken'], historyService.UploadMedia, {
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    });

  const { isLoading: isCreatingOrder, mutateAsync } = useMutation(
    ['createDIDOrder'],
    historyService.createDIDOrder,
    {
      onSuccess: ({ message, data }) => {
        queryClient.invalidateQueries('getProfile');
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'success' },
        });
        handleReset();
        queryClient.invalidateQueries('get-credit');
        navigate(`/profile`);
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );
  const {
    isLoading: isCreatingOrderFrance,
    mutateAsync: mutateAsyncBuyFrance,
  } = useMutation(
    ['createDIDOrderFrance'],
    historyService.createDIDOrderFrance,
    {
      onSuccess: ({ message, data }) => {
        queryClient.invalidateQueries('getProfile');
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'success' },
        });
        handleReset();
        queryClient.invalidateQueries('get-credit');
        navigate(`/profile`);
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );



  async function handleSubmitForm(values) {
    if (isErrorProfileType) return;
    const { name, did, picture, period, profileType, state } = values;
    const requireval =
      particularDid?.price &&
      particularDid?.price?.length > 0 &&
      particularDid?.price?.filter((v) => v.period === Number(period));

    let payloadValues = {};


    if (formik.values.country?.split('_')?.[1] === 'FR') {
      const formData = new FormData();
      formData.append('sku_id', particularDid?.sku_id);
      formData.append('did_id', did);
      formData.append('did_name', name);
      formData.append('period', requireval?.[0]?.period);
      formData.append('amount', requireval?.[0]?.cost);
      formData.append('currency', requireval?.[0]?.currency);
      formData.append('plan_id', requireval?.[0]?.planID);
      formData.append('type', profileType?.type);
      formData.append('app_picture', picture);
      formData.append('wallet_type', 'Credit');

      payloadValues.sku_id = particularDid?.sku_id;
      payloadValues.did_id = did;
      payloadValues.did_name = name;
      payloadValues.period = requireval?.[0]?.period;
      payloadValues.amount = requireval?.[0]?.cost;
      payloadValues.currency = requireval?.[0]?.currency;
      payloadValues.plan_id = requireval?.[0]?.planID;
      payloadValues.type = profileType?.type;
      payloadValues.wallet_type = 'Zeyi Pay';
      payloadValues.app_picture = '';

      if (formik.values.profileType.type === 'Personal') {
        formData.append('first_name', profileType?.first_name);
        formData.append('last_name', profileType?.last_name);
        formData.append('user_city', profileType?.user_city);
        formData.append('user_country', profileType?.user_country);
        formData.append('address', profileType?.address);
        formData.append('postal_code', profileType?.postal_code);
        formData.append('address_proof', filePreview);
        formData.append('proof', filePreviewProof);
        formData.append('address_proof_type_id', profileType?.address_proof_type_id || '');
        formData.append('proof_type_id', profileType?.proof_type_id || '');
        payloadValues.first_name = profileType?.first_name;
        payloadValues.last_name = profileType?.last_name;
        payloadValues.user_city = profileType?.user_city;
        payloadValues.user_country = profileType?.user_country;
        payloadValues.address = profileType?.address;
        payloadValues.postal_code = profileType?.postal_code;
        payloadValues.address_proof = profileType?.address_proof;
        payloadValues.address_proof_type_id = profileType?.address_proof_type_id || '';
        payloadValues.proof_type_id = profileType?.proof_type_id || '';
      } else {
        formData.append('first_name', profileType?.first_name);
        formData.append('last_name', profileType?.last_name);
        formData.append('user_city', profileType?.user_city);
        formData.append('user_country', profileType?.user_country);
        formData.append('address', profileType?.address);
        formData.append('postal_code', profileType?.postal_code);
        formData.append('address_proof', filePreview);
        formData.append('proof', filePreviewProof);
        formData.append('company_name', profileType?.company_name);
        formData.append('registration_num', profileType?.registration_num);
        formData.append('address_proof_type_id', profileType?.address_proof_type_id || '');
        formData.append('proof_type_id', profileType?.proof_type_id || '');
        formData.append('vat_id', profileType?.vat_id || '');
        formData.append('personal_tax_id', profileType?.personal_tax_id || '');
        payloadValues.first_name = profileType?.first_name;
        payloadValues.last_name = profileType?.last_name;
        payloadValues.user_city = profileType?.user_city;
        payloadValues.user_country = profileType?.user_country;
        payloadValues.address = profileType?.address;
        payloadValues.postal_code = profileType?.postal_code;
        payloadValues.company_name = profileType?.company_name;
        payloadValues.registration_num = profileType?.registration_num;
        payloadValues.address_proof_type_id = profileType?.address_proof_type_id || '';
        payloadValues.proof_type_id = profileType?.proof_type_id || '';
        payloadValues.vat_id = profileType?.vat_id || '';
        payloadValues.personal_tax_id = profileType?.personal_tax_id || '';
      }

      if (paymentType === 'wallet') {
        swal({
          text: `${requireval?.[0]?.cost} ${requireval?.[0]?.currency} will be deducted from your current credit balance.`,
          buttons: true,
          confirmButtonColor: '#ffcc00',
        }).then(async (willBuy) => {
          if (willBuy) {
            await mutateAsyncBuyFrance(formData);
          } else {
            return false;
          }
        });
      } else {
        const formDataImage = new FormData();
        formDataImage.append('media', picture);
        // const formDataAddresProofe = new FormData();
        // formDataAddresProofe.append('media', profileType?.address_proof);
        // const res1 = await mutateAsyncUploadMedia(formDataAddresProofe);
        if (picture) {
          const res = await mutateAsyncUploadMedia(formDataImage);
          if (res?.success) {
            payloadValues.app_picture = res?.data;
            const payloadToSend = {
              data: {
                ...payloadValues,
                address_proof: filePreview?.join(','),
                proof: filePreviewProof?.join(','),
              },
              amount: payloadValues?.amount,
              currency: payloadValues?.currency,
              msisdn: user?.phone,
              type: 'France VN',
            };
            await mutateAsyncPaymentToken(payloadToSend);
          }
        } else {
          payloadValues.app_picture = '';
          const payloadToSend = {
            data: {
              ...payloadValues,
              address_proof: filePreview?.join(','),
              proof: filePreviewProof?.join(','),
            },
            amount: payloadValues?.amount,
            currency: payloadValues?.currency,
            msisdn: user?.phone,
            type: 'France VN',
          };
          await mutateAsyncPaymentToken(payloadToSend);
        }
      }
    } else {
      const formData = new FormData();
      let payloadValuesNew = {};
      formData.append('state', state);
      formData.append('sku_id', particularDid?.sku_id);
      formData.append('did_id', did);
      formData.append('did_name', name);
      formData.append('period', requireval?.[0]?.period);
      formData.append('amount', requireval?.[0]?.cost);
      formData.append('currency', requireval?.[0]?.currency);
      formData.append('plan_id', requireval?.[0]?.planID);
      formData.append('type', 'Credit');
      formData.append('app_picture', picture);
      payloadValuesNew.state = state;
      payloadValuesNew.sku_id = particularDid?.sku_id;
      payloadValuesNew.did_id = did;
      payloadValuesNew.did_name = name;
      payloadValuesNew.period = requireval?.[0]?.period;
      payloadValuesNew.amount = requireval?.[0]?.cost;
      payloadValuesNew.currency = requireval?.[0]?.currency;
      payloadValuesNew.plan_id = requireval?.[0]?.planID;
      payloadValuesNew.type = 'Zeyi Pay';
      payloadValuesNew.app_picture = '';

      if (formik.values.profileType.type === 'Personal') {
        formData.append('first_name', profileType?.first_name);
        formData.append('last_name', profileType?.last_name);
        formData.append('user_city', profileType?.user_city);
        formData.append('user_country', profileType?.user_country);
        formData.append('address', profileType?.address);
        formData.append('postal_code', profileType?.postal_code);
        formData.append('address_proof', filePreview);
        formData.append('proof', filePreviewProof);
        formData.append('address_proof_type_id', profileType?.address_proof_type_id || '');
        formData.append('proof_type_id', profileType?.proof_type_id || '');
        payloadValuesNew.first_name = profileType?.first_name;
        payloadValuesNew.last_name = profileType?.last_name;
        payloadValuesNew.user_city = profileType?.user_city;
        payloadValuesNew.user_country = profileType?.user_country;
        payloadValuesNew.address = profileType?.address;
        payloadValuesNew.postal_code = profileType?.postal_code;
        payloadValuesNew.address_proof = profileType?.address_proof;
        payloadValuesNew.address_proof_type_id = profileType?.address_proof_type_id || '';
        payloadValuesNew.proof_type_id = profileType?.proof_type_id || '';

      } else {
        formData.append('first_name', profileType?.first_name);
        formData.append('last_name', profileType?.last_name);
        formData.append('user_city', profileType?.user_city);
        formData.append('user_country', profileType?.user_country);
        formData.append('address', profileType?.address);
        formData.append('postal_code', profileType?.postal_code);
        formData.append('address_proof', filePreview);
        formData.append('proof', filePreviewProof);
        formData.append('company_name', profileType?.company_name);
        formData.append('registration_num', profileType?.registration_num);
        formData.append('address_proof_type_id', profileType?.address_proof_type_id || '');
        formData.append('proof_type_id', profileType?.proof_type_id || '');
        formData.append('vat_id', profileType?.vat_id || '');
        formData.append('personal_tax_id', profileType?.personal_tax_id || '');
        payloadValuesNew.first_name = profileType?.first_name;
        payloadValuesNew.last_name = profileType?.last_name;
        payloadValuesNew.user_city = profileType?.user_city;
        payloadValuesNew.user_country = profileType?.user_country;
        payloadValuesNew.address = profileType?.address;
        payloadValuesNew.postal_code = profileType?.postal_code;
        payloadValuesNew.company_name = profileType?.company_name;
        payloadValuesNew.registration_num = profileType?.registration_num;
        payloadValuesNew.address_proof_type_id = profileType?.address_proof_type_id || '';
        payloadValuesNew.proof_type_id = profileType?.proof_type_id || '';
        payloadValuesNew.vat_id = profileType?.vat_id || '';
        payloadValuesNew.personal_tax_id = profileType?.personal_tax_id || '';

      }

      if (paymentType === 'wallet') {
        swal({
          text: `${requireval?.[0]?.cost} ${requireval?.[0]?.currency} will be deducted from your current credit balance.`,
          buttons: true,
          confirmButtonColor: '#ffcc00',
        }).then(async (willBuy) => {
          if (willBuy) {
            await mutateAsync(formData);
          } else {
            return false;
          }
        });
      } else {
        const formDataImage = new FormData();
        formDataImage.append('media', picture);

        if (picture) {
          const res = await mutateAsyncUploadMedia(formDataImage);
          if (res?.success) {
            payloadValuesNew.app_picture = res?.data;
            const payloadToSend = {
              data: {
                ...payloadValuesNew,
                address_proof: filePreview?.join(','),
                proof: filePreviewProof?.join(','),
              },
              amount: payloadValuesNew?.amount,
              currency: payloadValuesNew?.currency,
              msisdn: user?.phone,
              type: 'VN',
            };
            await mutateAsyncPaymentToken(payloadToSend);
          }
        } else {
          payloadValuesNew.app_picture = '';
          const payloadToSend = {
            data: {
              ...payloadValuesNew,
              address_proof: filePreview?.join(','),
              proof: filePreviewProof?.join(','),
            },
            amount: payloadValuesNew?.amount,
            currency: payloadValuesNew?.currency,
            msisdn: user?.phone,
            type: 'VN',
          };

          await mutateAsyncPaymentToken(payloadToSend);
        }
      }
    }
  }

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    mutateAsync: mutateAsyncCountry,
  } = useMutation(['getCountryList'], historyService.getCountryList, {
    onError: (error) => {
      let message = error.message;
      if (error.response?.data) message = error.response.data.message;
      dispatch({
        type: actionTypes.SET_SHOW_TOAST,
        value: { status: true, message, msgType: 'danger' },
      });
    },
  });

  const {
    data: stateData,
    isLoading: isLoadingState,
    mutateAsync: mutateAsyncState,
  } = useMutation(['getStateList'], (id) => historyService.getStateList(id), {
    onError: (error) => {
      let message = error.message;
      if (error.response?.data) message = error.response.data.message;
      dispatch({
        type: actionTypes.SET_SHOW_TOAST,
        value: { status: true, message, msgType: 'danger' },
      });
    },
  });
  const {
    data: cityData,
    isLoading: isLoadingCity,
    mutateAsync: mutateAsyncCity,
  } = useMutation(['getCityList'], (id) => historyService.getCityList(id), {
    onSuccess: ({ data }) => {
      if (data && data.length > 0) {
        setCityLists(data);
      } else {
        setCityLists([]);
      }
    },
    onError: (error) => {
      let message = error.message;
      if (error.response?.data) message = error.response.data.message;
      dispatch({
        type: actionTypes.SET_SHOW_TOAST,
        value: { status: true, message, msgType: 'danger' },
      });
    },
  });
  const {
    data: cityDataUS,
    isLoading: isLoadingCityUS,
    mutateAsync: mutateAsyncCityUS,
  } = useMutation(
    ['getCityListUS'],
    () => historyService.getCityListUS(formik.values.state),
    {
      onSuccess: ({ data }) => {
        if (data && data.length > 0) {
          setCityLists(data);
        } else {
          setCityLists([]);
        }
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );
  const {
    data: cityDataFrance,
    isLoading: isLoadingCityFrance,
    mutateAsync: mutateAsyncCityFrance,
  } = useMutation(['getCityListFrance'], historyService.getFranceCityList, {
    onSuccess: ({ data }) => {
      if (data && data.length > 0) {
        setCityLists(data);
      } else {
        setCityLists([]);
      }
    },
    onError: (error) => {
      let message = error.message;
      if (error.response?.data) message = error.response.data.message;
      dispatch({
        type: actionTypes.SET_SHOW_TOAST,
        value: { status: true, message, msgType: 'danger' },
      });
    },
  });
  const {
    data: didData,
    isLoading: isLoadingDid,
    mutateAsync: mutateAsyncDid,
  } = useMutation(
    ['getDidNumberList'],
    () => historyService.getDidNumberList(formik.values.city),
    {
      onSuccess: ({ data }) => {
        if (data && data.length > 0) {
          setDidData(data);
        } else {
          setDidData([]);
        }
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );
  const {
    data: didDataFrance,
    isLoading: isLoadingDidFrance,
    mutateAsync: mutateAsyncDidFrance,
  } = useMutation(
    ['getFranceDidNumberList'],
    (id) => historyService.getFranceDidNumberList(id),
    {
      onSuccess: ({ data }) => {
        if (data && data.length > 0) {
          setDidData(data);
        } else {
          setDidData([]);
        }
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );
  const {
    data: didDataUk,
    isLoading: isLoadingDidUk,
    mutateAsync: mutateAsyncDidUk,
  } = useMutation(
    ['getUkDidNumberList'],
    (id) => historyService.countryDidNumber(id),
    {
      onSuccess: ({ data }) => {
        if (data && data.length > 0) {
          setDidData(data);
        } else {
          setDidData([]);
        }
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  const {
    data: didDetails,
    isLoading: isLoadingDidDetails,
    mutateAsync: mutateAsyncDidDetails,
  } = useMutation(
    ['getDIDDetails'],
    () => historyService.getDIDDetails(formik.values.did),
    {
      onSuccess: ({ data }) => {
        setparticularDid(data);
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );
  const {
    isLoading: isLoadinggetDIDDetailsFrance,
    mutateAsync: mutateAsyncDidDetailsFrance,
  } = useMutation(
    ['getDIDDetailsFrance'],
    () => historyService.getDIDDetailsFrance(formik.values.did),
    {
      onSuccess: ({ data }) => {
        setparticularDid(data);
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  async function handleCountry() {
    await mutateAsyncCountry();
  }
  async function handleCity(id) {
    await mutateAsyncCity(id);
  }
  async function handleCityUS() {
    await mutateAsyncCityUS();
  }
  async function handleState(id) {
    await mutateAsyncState(id);
  }
  async function handleCityFrance() {
    await mutateAsyncCityFrance();
  }
  async function handleDid() {
    await mutateAsyncDid();
  }
  async function handleDidFrance(id) {
    await mutateAsyncDidFrance(id);
  }
  async function handleDidUk(id) {
    await mutateAsyncDidUk(id);
  }
  async function handleDidDetails() {
    await mutateAsyncDidDetails();
  }
  async function handleDidDetailsFrance() {
    await mutateAsyncDidDetailsFrance();
  }

  const handleRemovePreview = (index) => {
    let tempFiles = [...filePreview];
    setFilePreview([...tempFiles.filter((_, idx) => idx !== index)]);
  };
  const handleRemoveProofPreview = (index) => {
    let tempFiles = [...filePreviewProof];
    setFilePreviewProof([...tempFiles.filter((_, idx) => idx !== index)]);
  };

  const handleChange = async (event, type = 'address_proof') => {
    setIsLoading(type);
    const files = event.currentTarget.files;
    if (!files) return;
    const formData = new FormData();
    let filesArr = [];
    for (let file of files) {
      formData.append('media', file);
      try {
        const res = await mutateAsyncUploadMedia(formData);
        if (res?.success) {
          filesArr.push(res?.data);
        }
      } catch (error) {
        console.error({ error });
        setIsLoading(null);
      }
    }
    setIsLoading(null);
    if (type === 'address_proof') {
      setFilePreview((prevState) => [...prevState, ...filesArr]);
      formik.setFieldValue('profileType.address_proof', files);
    }
    if (type === 'proof') {
      setFilePreviewProof((prevState) => [...prevState, ...filesArr]);
      formik.setFieldValue('profileType.proof', files);
    }
  };



  useEffect(() => {
    handleCountry();

    return () => {
      formik.resetForm();
      setparticularDid({});
    };
  }, []);




  useEffect(() => {
    if (filePreview.length > 0) {
      formik.setFieldValue('profileType.address_proof', 'file');
    } else {
      formik.setFieldValue('profileType.address_proof', '');
    }
  }, [filePreview])
  useEffect(() => {
    if (filePreviewProof.length > 0) {
      formik.setFieldValue('profileType.proof', 'file');
    } else {
      formik.setFieldValue('profileType.proof', '');
    }
  }, [filePreviewProof])





  useEffect(() => {
    if (formik.values.country) {
      const ISO_VAL = formik.values.country?.split('_');
      const ISO = ISO_VAL?.[1];

      handleReset();

      const resetProfileType = {
        type: '',
        first_name: '',
        last_name: '',
        user_city: '',
        user_country: '',
        company_name: '',
        address: '',
        registration_num: '',
        postal_code: '',
        address_proof: '',
      };
      formik.setFieldValue('city', '');
      formik.setFieldValue('did', '');
      formik.setFieldValue('period', '');
      formik.setFieldValue('picture', '');
      formik.setFieldValue('state', '');
      formik.setFieldValue('profileType', resetProfileType);
      if (ISO_VAL?.[1] === 'FR') {
        // handleCityFrance();
        handleDidFrance(ISO_VAL?.[0]);
      } else if (
        ISO === 'GB' ||
        ISO === 'UA' ||
        ISO === 'BE' ||
        ISO === 'PL' ||
        ISO === 'SE'
      ) {
        handleDidUk(ISO_VAL?.[0]);
      } else if (ISO_VAL?.[1] === 'US') {
        handleState(ISO_VAL?.[0]);
      } else {
        handleCity(ISO_VAL?.[0]);
      }
    }


    setFilePreview([])
    setFilePreviewProof([])
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.state) {
      handleCityUS();
    }
  }, [formik.values.state]);

  useEffect(() => {
    if (formik.values.city) {
      if (formik.values.country?.split('_')?.[1] === 'FR') {
        handleDidFrance(formik.values.country?.split('_')?.[0]);
      } else {
        handleDid();
      }
    }
  }, [formik.values.city]);

  useEffect(() => {
    if (formik.values.did) {
      if (formik.values.country?.split('_')?.[1] === 'FR') {
        handleDidDetailsFrance();
      } else {
        handleDidDetails();
      }
    }
  }, [formik.values.did]);

  useEffect(() => {
    const ISO_VAL = formik?.values?.country?.split('_');
    const ISO = ISO_VAL?.[1];
    if (["FR", "PL", "BE"].includes(ISO) && !formik?.values?.profileType?.type) {
      setIsErrorProfileType(true)
    } else {
      setIsErrorProfileType(false)
    }
  }, [formik?.values?.country, formik?.values?.profileType?.type])




  const shouldLoad =
    isCreatingOrder || isCreatingOrderFrance || isLoadingPaymentToken;


  return (
    <div className="Card_form">
      <Card>
        <Card.Header className="p-3">Add Profile</Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit} className="setting_form w-100">
            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Name
              </Form.Label>
              <div className="d-flex flex-column w-100">
                <Form.Control
                  className="form-control"
                  placeholder="Enter name"
                  aria-label="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="error_required w-100 text-danger font-11 ">
                    {formik.errors.name}
                  </div>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Country
              </Form.Label>
              <div className="d-flex flex-column w-100">
                <Form.Select
                  className="form-control"
                  name="country"
                  disabled={isLoadingCountry}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                >
                  <option value="">Please select country</option>
                  {countryData?.data &&
                    countryData?.data?.length > 0 &&
                    countryData?.data?.map(({ id, iso, name, prefix }) => (
                      <option key={iso} value={`${id}_${iso}`}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
                {formik.touched.country && formik.errors.country && (
                  <div className="error_required w-100 text-danger font-11 ">
                    {formik.errors.country}
                  </div>
                )}
              </div>
            </Form.Group>
            {formik.values.country?.split('_')?.[1] === 'US' && (
              <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                <Form.Label className="w-100 font-15 font-600 text-grey">
                  State
                </Form.Label>
                <div className="d-flex flex-column w-100">
                  <Form.Select
                    className="form-control"
                    name="state"
                    disabled={isLoadingState}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                  >
                    <option value="">Please select state</option>
                    {stateData?.data &&
                      stateData?.data?.length > 0 &&
                      stateData?.data?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </Form.Select>
                  {formik.touched.state && formik.errors.state && (
                    <div className="error_required w-100 text-danger font-11 ">
                      {formik.errors.state}
                    </div>
                  )}
                </div>
              </Form.Group>
            )}
            {!countriesArr.includes(formik.values.country?.split('_')?.[1]) && (
              <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                <Form.Label className="w-100 font-15 font-600 text-grey">
                  City
                </Form.Label>
                <div className="d-flex flex-column w-100">
                  <Form.Select
                    className="form-control"
                    name="city"
                    disabled={
                      isLoadingCity || isLoadingCityFrance || isLoadingCityUS
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  >
                    <option value="">Please select city</option>
                    {cityLists &&
                      cityLists?.length > 0 &&
                      cityLists?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </Form.Select>
                  {formik.touched.city && formik.errors.city && (
                    <div className="error_required w-100 text-danger font-11 ">
                      {formik.errors.city}
                    </div>
                  )}
                </div>
              </Form.Group>
            )}

            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                DID Number
              </Form.Label>
              <div className="d-flex flex-column w-100">
                <Form.Select
                  className="form-control"
                  name="did"
                  disabled={isLoadingDid || isLoadingDidFrance}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.did}
                >
                  <option value="">Please select number</option>

                  {allDidData &&
                    allDidData?.length > 0 &&
                    allDidData.map(({ id, number }) => (
                      <option key={id} value={id}>
                        {number}
                      </option>
                    ))}
                </Form.Select>
                {formik.touched.did && formik.errors.did && (
                  <div className="error_required w-100 text-danger font-11 ">
                    {formik.errors.did}
                  </div>
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Period
              </Form.Label>
              <div className="d-flex flex-column w-100">
                <Form.Select
                  className="form-control"
                  name="period"
                  disabled={isLoadingDidDetails || isLoadinggetDIDDetailsFrance}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.period}
                >
                  <option value="">Please select period</option>
                  {particularDid &&
                    particularDid?.price &&
                    particularDid?.price?.length > 0 &&
                    particularDid?.price?.map(
                      ({ period, cost, currency }, index) => (
                        <option key={index} value={period}>
                          {`${period} (${cost}  ${currency})`}
                        </option>
                      ),
                    )}
                </Form.Select>
                {formik.touched.period && formik.errors.period && (
                  <div className="error_required w-100 text-danger font-11 ">
                    {formik.errors.period}
                  </div>
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Profile Picture
              </Form.Label>
              <div className="d-flex flex-column w-100">
                <Form.Control
                  type="file"
                  accept="image/*"
                  placeholder="select picture"
                  aria-label="picture"
                  name="picture"
                  onChange={(event) => {
                    formik.setFieldValue(
                      'picture',
                      event.currentTarget.files[0],
                    );
                  }}
                />
              </div>
            </Form.Group>
            {(selectedCountryISO === 'FR' ||
              selectedCountryISO === 'PL' ||
              selectedCountryISO === 'BE') && (
                <>
                  <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                    <Form.Label className="w-100 font-15 font-600 text-grey">
                      Profile Type
                    </Form.Label>
                    <div className="d-flex flex-column w-100">
                      <Form.Select
                        className="form-control"
                        name="profileType.type"
                        disabled={isLoadingDidDetails}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.profileType?.type}
                      >
                        <option value="">Please select profile type</option>
                        <option key="0" value="Business">
                          Business
                        </option>
                        <option key="1" value="Personal">
                          Personal
                        </option>
                      </Form.Select>
                      {isErrorProfileType ? (
                        <div className="error_required w-100 text-danger font-11 ">
                          Profile Type is required
                        </div>
                      ) : ''}
                    </div>
                  </Form.Group>
                </>
              )}
            {formik.values.profileType.type === 'Business' && (
              <>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    First Name
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter first name"
                      aria-label="first_name"
                      name="profileType.first_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.first_name}
                    />
                    {formik.touched.profileType?.first_name &&
                      formik.errors.profileType?.first_name && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.first_name}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Last Name
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter last name"
                      aria-label="last_name"
                      name="profileType.last_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.last_name}
                    />
                    {formik.touched.profileType?.last_name &&
                      formik.errors.profileType?.last_name && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.last_name}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Company Name
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter company name"
                      aria-label="company_name"
                      name="profileType.company_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.company_name}
                    />
                    {formik.touched.profileType?.company_name &&
                      formik.errors.profileType?.company_name && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.company_name}
                        </div>
                      )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Address
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter address"
                      aria-label="address"
                      name="profileType.address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.address}
                    />
                    {formik.touched.profileType?.address &&
                      formik.errors.profileType?.address && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.address}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    User City
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter city"
                      aria-label="user_city"
                      name="profileType.user_city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType.user_city}
                    />
                    {formik.touched.profileType?.user_city &&
                      formik.errors.profileType?.user_city && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.user_city}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    User Country
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Select
                      className="form-control"
                      name="profileType.user_country"
                      disabled={isLoadingCountry}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType.user_country}
                    >
                      <option value="">Please select country</option>
                      {Country.getAllCountries().map(({ isoCode, name }) => (
                        <option key={isoCode} value={isoCode}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                    {formik.touched.profileType?.user_country &&
                      formik.errors.profileType?.user_country && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.user_country}
                        </div>
                      )}
                  </div>
                </Form.Group>

                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Company Registration Number
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter company rigestration number"
                      aria-label="registration_num"
                      name="profileType.registration_num"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.registration_num}
                    />
                    {formik.touched.profileType?.registration_num &&
                      formik.errors.profileType?.registration_num && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.registration_num}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    VAT Number
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter number"
                      aria-label="profileType.vat_id"
                      name="profileType.vat_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.profileType?.vat_id}
                    />
                    {formik.touched?.profileType?.company_name && formik.errors?.profileType?.vat_id && (
                      <div className="error_required w-100 text-danger font-11 ">
                        {formik.errors?.profileType?.vat_id}
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="personal_tax_id">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    TAX Number
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter number"
                      aria-label="name"
                      name="profileType.personal_tax_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.profileType?.personal_tax_id}
                    />
                    {formik.touched?.profileType?.company_name && formik.errors?.profileType?.personal_tax_id && (
                      <div className="error_required w-100 text-danger font-11 ">
                        {formik.errors?.profileType?.personal_tax_id}
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Postal Code
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Postal code"
                      aria-label="postal_code"
                      name="profileType.postal_code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.postal_code}
                    />
                    {formik.touched.profileType?.postal_code &&
                      formik.errors.profileType?.postal_code && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.postal_code}
                        </div>
                      )}
                  </div>
                </Form.Group>

              </>
            )}
            {formik.values.profileType.type === 'Personal' && (
              <>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    First Name
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter first name"
                      aria-label="first_name"
                      name="profileType.first_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.first_name}
                    />
                    {formik.touched.profileType?.first_name &&
                      formik.errors.profileType?.first_name && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.first_name}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Last Name
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter last name"
                      aria-label="last_name"
                      name="profileType.last_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.last_name}
                    />
                    {formik.touched.profileType?.last_name &&
                      formik.errors.profileType?.last_name && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.last_name}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Address
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter address"
                      aria-label="address"
                      name="profileType.address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.address}
                    />
                    {formik.touched.profileType?.address &&
                      formik.errors.profileType?.address && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.address}
                        </div>
                      )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    User City
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Enter City"
                      aria-label="user_city"
                      name="profileType.user_city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.profileType?.user_city}
                    />
                    {formik.touched.profileType?.user_city && formik.errors.profileType?.user_city && (
                      <div className="error_required w-100 text-danger font-11 ">
                        {formik.errors.profileType?.user_city}
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    User Country
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Select
                      className="form-control"
                      name="profileType.user_country"
                      disabled={isLoadingCountry}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.user_country}
                    >
                      <option value="">Please select country</option>
                      {Country.getAllCountries().map(({ isoCode, name }) => (
                        <option key={isoCode} value={isoCode}>
                          {name}
                        </option>
                      ))}
                    </Form.Select>
                    {formik.touched.profileType?.user_country && formik.errors.profileType?.user_country && (
                      <div className="error_required w-100 text-danger font-11 ">
                        {formik.errors.profileType?.user_country}
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Postal Code
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      className="form-control"
                      placeholder="Postal code"
                      aria-label="postal_code"
                      name="profileType.postal_code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profileType?.postal_code}
                    />
                    {formik.touched.profileType?.postal_code &&
                      formik.errors.profileType?.postal_code && (
                        <div className="error_required w-100 text-danger font-11 ">
                          {formik.errors.profileType?.postal_code}
                        </div>
                      )}
                  </div>
                </Form.Group>
              </>
            )}

            {(typeOfProfile === 'Business' || typeOfProfile === 'Personal') && (
              <>
                <Form.Group className="mb-3 d-flex" controlId="address_proof_type_id">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Address Proof Type
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Select
                      className="form-control"
                      name="profileType.address_proof_type_id"
                      disabled={isLoadingDidDetails}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.address_proof_type_id}
                    >
                      <option value="">Please select type</option>
                      {dataAddressProofType && dataAddressProofType?.length > 0 ? (
                        dataAddressProofType.map((res, index) => {
                          return (
                            <option key={index} value={res?.id}>
                              {res?.attributes?.name}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">No record found</option>
                      )}
                    </Form.Select>
                    {formik.touched.profileType?.address &&
                      formik.errors.profileType?.address_proof_type_id ? (
                      <div className="error_required w-100 text-danger font-11">
                        {formik.errors.profileType?.address_proof_type_id}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>


                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Address Proof
                  </Form.Label>
                  <Form.Control
                    ref={addressProofRef}
                    type="file"
                    accept="image/*"
                    placeholder="select address proof"
                    aria-label="address_proof"
                    name="profileType.address_proof"
                    onChange={(event) => handleChange(event, 'address_proof')}
                    multiple
                    hidden
                  />
                  <div className='d-flex flex-column w-100'>
                    <div className="d-flex align-items-center w-100 gap-2 ">
                      <button onClick={() => addressProofRef?.current?.click()} type='button' className='btn btn-warning'>
                        Choose files
                      </button>
                      {
                        filePreview?.length > 0 ? (
                          <span>{filePreview?.length} {filePreview?.length === 1 ? 'file' : 'files'}</span>

                        ) : null
                      }
                    </div>
                    {formik.touched.profileType?.address &&
                      formik.errors.profileType?.address_proof ? (
                      <div className="error_required w-100 text-danger font-11">
                        {formik.errors.profileType?.address_proof}
                      </div>
                    ) : null}</div>
                </Form.Group>
                <div className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">

                  </Form.Label>

                  <div
                    className="d-flex gap-2 flex-wrap"
                    style={{
                      maxWidth: '60%',

                    }}

                  >
                    {isLoading === "address_proof" ? (
                      <div> <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                        {' '}
                        <span> Please wait...</span></div>
                    ) : filePreview &&
                      Array.isArray(filePreview) &&
                      filePreview.length > 0
                      ? filePreview.map((file, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              position: 'relative',
                              background: '#f5f5f5',
                              padding: '5px',
                              borderRadius: '5px',
                              width: '120px',
                              height: '120px'
                            }}
                          >
                            <span
                              style={{
                                position: 'absolute',
                                right: '-4px',
                                top: '-5px',
                                cursor: 'pointer',
                                background: '#ddd',
                                width: '16px',
                                height: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100px',
                                fontSize: '8px',
                                border: '1px solid #fff',
                              }}
                              onClick={() => handleRemovePreview(index)}
                            >
                              X
                            </span>
                            <img
                              src={file}
                              style={{

                                width: '100%',
                              }}
                            />
                          </div>
                        );
                      })
                      : null}
                  </div>

                </div></>
            )}

            {(typeOfProfile === 'Business' || typeOfProfile === 'Personal') && (
              <>
                <Form.Group className="mb-3 d-flex" controlId="proof_type_id">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    {`${typeOfProfile} Proof Type`}
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Select
                      className="form-control"
                      name="profileType.proof_type_id"
                      disabled={isLoadingDidDetails}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.proof_type_id}
                    >
                      <option value="">Please select type</option>
                      {dataProofTypeID && dataProofTypeID?.length > 0 ? (
                        dataProofTypeID.map((res, index) => {
                          return (
                            <option key={index} value={res?.id}>
                              {res?.attributes?.name}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">No record found</option>
                      )}
                    </Form.Select>
                    {formik.touched.profileType?.address &&
                      formik.errors.profileType?.proof_type_id ? (
                      <div className="error_required w-100 text-danger font-11">
                        {formik.errors.profileType?.proof_type_id}
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    {`${typeOfProfile} Proof`}
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <Form.Control
                      ref={proofRef}
                      type="file"
                      accept="image/*"
                      aria-label="proof"
                      name="profileType.proof"
                      onChange={(event) => handleChange(event, 'proof')}
                      multiple
                      hidden
                    />
                    <div className='d-flex flex-column w-100'>

                      <div className="d-flex align-items-center w-100 gap-2 ">
                        <button onClick={() => proofRef?.current?.click()} type='button' className='btn btn-warning'>
                          Choose files
                        </button>
                        {
                          filePreviewProof?.length > 0 ? (
                            <span>{filePreviewProof?.length} {filePreviewProof?.length === 1 ? 'file' : 'files'}</span>

                          ) : null
                        }
                      </div>
                      {formik.touched.profileType?.address &&
                        formik.errors.profileType?.proof ? (
                        <div className="error_required w-100 text-danger font-11">
                          {formik.errors.profileType?.proof}
                        </div>
                      ) : null}
                    </div>
                  </div>

                </Form.Group>
                <div className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">

                  </Form.Label>
                  <div className="gap-2 d-flex flex-wrap "
                    style={{
                      maxWidth: '60%',

                    }}>
                    {isLoading === 'proof' ? (
                      <div> <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                        {' '}
                        <span> Please wait...</span></div>
                    ) : filePreviewProof &&
                      Array.isArray(filePreviewProof) &&
                      filePreviewProof.length > 0
                      ? filePreviewProof.map((file, index) => {
                        return (
                          <div
                            key={index}
                            className=" d-inline-block"
                            style={{
                              position: 'relative',
                              background: '#f5f5f5',
                              padding: '5px',
                              borderRadius: '5px',
                              width: '120px',
                              height: '120px'
                            }}
                          >
                            <span
                              style={{
                                position: 'absolute',
                                right: '-4px',
                                top: '-5px',
                                cursor: 'pointer',
                                background: '#ddd',
                                width: '16px',
                                height: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100px',
                                fontSize: '8px',
                                border: '1px solid #fff',
                              }}
                              onClick={() => handleRemoveProofPreview(index)}
                            >
                              X
                            </span>
                            <img
                              src={file}
                              style={{

                                width: '100%',
                              }}
                            />
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>

              </>
            )}

            <Form.Group
              className="mb-3 gap-1 d-flex"
              controlId="formBasicEmail"
            >
              <Form.Label className="w-100 font-15 font-600 text-grey"></Form.Label>
              {shouldLoad ? (
                <Button type="button" disabled={true}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />

                  <span
                    className={classNames({ 'visually-hidden': shouldLoad })}
                  >
                    Please wait...
                  </span>
                </Button>
              ) : (
                <>
                  <button
                    type="submit"
                    onClick={() => setPaymentMethod('zeyi')}
                    className="btn btn-dark btn-sm"
                    disabled={
                      isCreatingOrder ||
                      isCreatingOrderFrance ||
                      isLoadingPaymentToken ||
                      isLoadingMedia
                    }
                  >
                    Pay with Zeyi
                  </button>

                  <button
                    type="submit"
                    onClick={() => setPaymentMethod('wallet')}
                    className="btn btn-warning btn-sm"
                    disabled={
                      isCreatingOrder ||
                      isCreatingOrderFrance ||
                      isLoadingPaymentToken ||
                      isLoadingMedia
                    }
                  >
                    Pay with Wallet
                  </button>
                </>
              )}
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProfileAdd;
