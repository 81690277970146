import { Routes, Route } from 'react-router-dom';
import Main from '../components/layouts/Main';
import ErrorPage from '../components/pageNotFound';
import {
  AboutPage,
  RatePage,
  FeaturesPage,
  FaqPage,
  FaqInnerPage,
  FaqHowItWork,
  FaqTechnicalPage,
  FaqAccount,
  FaqCalling,
  FaqMessage,
  FaqBilling,
  FaqNumbers,
  FaqDownloads,
  PrivacyAndPolicy,
  TermsPage,
  ContactPage,
  CookiesPage,
  AccountPage,
  TopupPage,
  ActionPage,
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  OtpPage,
  ProfilePage,
  ResetPasswordPage,
  SignupPage,
  HistoryPage,
  SettingPage,
  AddMinuteBundle,
  AddSmsBundle,
} from '../pages';
import FaqHead from '../pages/FaqHead';
import ProfileAdd from '../pages/ProfileAdd';
import ProfileDetail from '../pages/ProfileDetail';
import SmsForm from '../pages/SmsForm';
import TopupType from '../pages/TopupType';
import TransferCredit from '../pages/TransferCredit';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        {/* restricted public routes */}
        <Route element={<PublicRoutes restricted={true} />}>
          <Route index element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="change-password" element={<ResetPasswordPage />} />
          <Route path="otp" element={<OtpPage />} />
          <Route path="verify-pin" element={<OtpPage />} />
        </Route>
        {/* public routes */}
        <Route element={<PublicRoutes />}>
          <Route path="home" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="rate" element={<RatePage />} />
          <Route path="features" element={<FeaturesPage />} />
          {/* <Route path="faq" element={<FaqPage />} /> */}
          <Route path="faq" element={<FaqHead />}>
            <Route index element={<FaqPage />} />
            <Route path="faq-how-it-work" element={<FaqHowItWork />} />
            <Route path="faq-technical" element={<FaqTechnicalPage />} />
            <Route path="faq-account" element={<FaqAccount />} />
            <Route path="faq-calling" element={<FaqCalling />} />
            <Route path="faq-message" element={<FaqMessage />} />
            <Route path="faq-billing" element={<FaqBilling />} />
            <Route path="faq-numbers" element={<FaqNumbers />} />
            <Route path="faq-downloads" element={<FaqDownloads />} />
          </Route>
          <Route path="privacy" element={<PrivacyAndPolicy />} />
          <Route path="cookies" element={<CookiesPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="contact" element={<ContactPage />} />
        </Route>
        {/* protacted routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="profile" element={<ProfilePage />}>
            <Route path=":number" element={<ProfileDetail />} />
            <Route path="add-profile" element={<ProfileAdd />} />
          </Route>
          <Route path="account" element={<AccountPage />} />
          <Route path="account/topup" element={<TopupPage />} />
          <Route path="account/topup/:type" element={<TopupType />} />
          <Route path="action" element={<ActionPage />}>
            <Route index={true} element={<SmsForm />} />
            <Route path="transfer-credit" element={<TransferCredit />} />
          </Route>
          <Route path="history" element={<HistoryPage />} />
          <Route path="setting" element={<SettingPage />} />
          <Route path="add-minute-bundle" element={<AddMinuteBundle />} />
          <Route path="add-sms-bundle" element={<AddSmsBundle />} />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AppRoutes;
