import { apiClient } from './config';

export const changePassword = (payload) => {
  return apiClient({
    method: 'POST',
    url: '/api/reset-password-web',
    data: payload,
  });
};
export const rates = (payload) => {
  return apiClient({
    method: 'POST',
    url: '/api/rate-list-call-sms',
    data: payload,
  });
};
export const addressProofType = () => {
  return apiClient({
    method: 'GET',
    url: '/api/proof_types/Address',
  });
};


export const proofTypeID = ({ type }) => {
  return apiClient({
    method:'GET',
    url: `/api/proof_types/${type}`,
  });
};

