import Toaster from '../common/Toaster';
import AppNavbar from './AppNavbar';

const AppHeader = () => {
  return (
    <header className="fixed-top bg-white">
      <Toaster />
      <AppNavbar />
    </header>
  );
};

export default AppHeader;
