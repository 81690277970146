import { useStateValue } from './context';
import { ErrorBoundary } from 'react-error-boundary';
import { AppRoutes } from './routes';
import { Suspense, useEffect } from 'react';
import Loader from './components/common/Loader';
import Error500Page from './pages/Error500Page';
import { actionTypes } from './context/reducer';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const [{ refresh_token, access: isAuthenticated }, dispatch] =
    useStateValue();
  const navigate = useNavigate();

  const parseQuery = async (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === '?' ? queryString.substr(1) : queryString
    ).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  };
  const handleLogin = async (search) => {
    const { access, refresh, user } = await parseQuery(search);
    if (access && refresh) {
      const userToSet = JSON.parse(user);
      dispatch({
        type: actionTypes.SET_TOKEN,
        value: { access_token: access, refresh_token: refresh },
      });
      dispatch({
        type: actionTypes.SET_USER,
        value: { ...userToSet },
      });
    }
  };

  useEffect(() => {
    const { search } = window.location;
    if (search) {
      handleLogin(search);
    }
  }, []);

  // useEffect(() => {
  //   const fiveMinutes = 1000 * 60 * 5;
  //   const interval = setInterval(refreshNotifications, fiveMinutes);
  //   refreshNotifications(interval);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [isAuthenticated]);

  // const refreshNotifications = async (interval) => {
  //   if (isAuthenticated) {
  //     try {
  //       const { access_token } = await loginService.refreshToken({
  //         refresh_token,
  //       });
  //       console.log(
  //         '🚀 ~ file: App.jsx ~ line 32 ~ refreshNotifications ~ data',
  //         access_token,
  //       );
  //       // dispatch({
  //       //   type: actionTypes.UPDATE_ACCESS_TOKEN,
  //       //   value: access_token,
  //       // });
  //     } catch (error) {
  //       clearInterval(interval);
  //       dispatch({ type: actionTypes.SET_USER_LOGOUT, value: '' });
  //       navigate('/');
  //     }
  //   } else {
  //     clearInterval(interval);
  //   }
  // };

  return (
    <ErrorBoundary FallbackComponent={Error500Page}>
      <Suspense fallback={<Loader />}>
        <AppRoutes />
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
