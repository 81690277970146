import { Button, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

const SubmitButton = ({ isLoading, label, ...rest }) => {
  return (
    <Button {...rest} disabled={isLoading}>
      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <span className={classNames({ 'visually-hidden': isLoading })}>
        {label}
      </span>
    </Button>
  );
};

export default SubmitButton;
