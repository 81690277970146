import React from 'react';
import { Container, Image, Nav } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import MessagingIcon from '../images/faq-messaging.svg';
import NumberIcon from '../images/faq-numbers.svg';
import InfoIcon from '../images/faq-infos.svg';
import AccountIcon from '../images/faq-account.svg';
import BillingIcon from '../images/faq-billing.svg';
import CallingIcon from '../images/faq-calling.svg';
import CogIcon from '../images/faq-cog.svg';
import DownloadIcon from '../images/faq-downloads.svg';
import classNames from 'classnames';

const categories = [
  {
    to: '/faq/faq-how-it-work',
    icon: InfoIcon,
    title: 'How it work',
  },
  { to: '/faq/faq-technical', icon: CogIcon, title: 'Technical requirements' },
  {
    to: '/faq/faq-account',
    icon: AccountIcon,
    title: 'Account',
  },
  {
    to: '/faq/faq-calling',
    icon: CallingIcon,
    title: 'Calling',
  },
  {
    to: '/faq/faq-message',
    icon: MessagingIcon,
    title: 'Messaging',
  },
  {
    to: '/faq/faq-billing',
    icon: BillingIcon,
    title: 'Billing',
  },
  {
    to: '/faq/faq-numbers',
    icon: NumberIcon,
    title: 'Zeyi Numbers',
  },
  {
    to: '/faq/faq-downloads',
    icon: DownloadIcon,
    title: 'Downloads',
  },
];

const FaqHead = () => {
  const location = useLocation();
  if (location.pathname === '/faq') return <Outlet />;
  return (
    <div className="Page_sec faqPage">
      <div className="w-100 ">
        <div className="slide_ban d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center h-100">
            <Container>
              <h2 className="font-50 font-300 text-white mb-0 text-center d-flex align-items-center justify-content-center">
                FAQ
              </h2>
            </Container>
          </div>
        </div>
        <div className="bg-light">
          <Container>
            <div className="icons-sec d-flex justify-content-between py-3 ">
              {categories.map((category, index) => (
                <div
                  key={index}
                  className={classNames(
                    'icon-item d-flex flex-column align-items-center justify-content-center w-100 pointer me-1',
                    { active: category.to === location.pathname },
                  )}
                >
                  <Nav.Link
                    as={Link}
                    active={true}
                    to={category.to}
                    className="w-100 text-center"
                  >
                    <Image src={category.icon} alt={category.title} />
                    <p className="font-14 m-0 font-400 mt-2 text-center">
                      {category.title}
                    </p>
                  </Nav.Link>
                </div>
              ))}
            </div>
          </Container>
        </div>
        <div className="w-100 ">
          <Container>
            <Outlet />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default FaqHead;
