import React, { useState } from 'react';
import { Card, Form, Button, Image } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useStateValue } from '../context';
import { actionTypes } from '../context/reducer';
import historyService from '../services/historyService';
import RenewPage from './RenewPage';
import { ReactComponent as UserIcon } from '../images/user.svg';
const ProfileDetail = () => {
  const { state } = useLocation();
  const queryClient = useQueryClient();
  const [renew, setRenew] = useState(false);
  const [_, dispatch] = useStateValue();
  const { isLoading, mutateAsync } = useMutation(
    ['deleteNumber'],
    historyService.deleteNumber,
    {
      onSuccess: ({ message }) => {
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: {
            status: true,
            message: message,
            msgType: 'success',
          },
        });
        queryClient.invalidateQueries('getProfile');
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  async function handleDelete() {
    const payload = {
      uniquecode: state?.number?.uniquecode ?? '',
      last_digit: state?.number?.did_number?.slice(-4) ?? '',
    };
    await mutateAsync(payload);
  }

  return (
    <div className="Card_form">
      <Card>
        <Card.Header className="p-3">Featured</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group
              className="mb-3 d-flex align-items-center"
              controlId="formBasicEmail"
            >
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Picture
              </Form.Label>
              <Form.Label className="w-100 form-text font-15 font-400">
                <div className="commanCard_cir bg-dark text-white  radius-100 d-flex justify-content-center align-items-center font-20 pointer  ">
                  {state?.number?.app_picture ? (
                    <Image
                      src={state?.number?.app_picture}
                      alt="profile pic"
                      className="w-100 rounded"
                    />
                  ) : (
                    <UserIcon />
                  )}
                </div>
              </Form.Label>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Name
              </Form.Label>
              <Form.Label className="w-100 form-text font-15 font-400">
                {state?.number?.did_name}
              </Form.Label>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Number
              </Form.Label>
              <Form.Label className="w-100 form-text font-15 font-400">
                {state?.number?.did_number}
              </Form.Label>
            </Form.Group>

            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Location
              </Form.Label>
              <Form.Label className="w-100 form-text font-15 font-400">
                {state?.number?.did_country}
              </Form.Label>
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
              <Form.Label className="w-100 font-15 font-600 text-grey">
                Expires
              </Form.Label>
              <Form.Label className="w-100 form-text font-15 font-400">
                {state?.number?.expired_at}
              </Form.Label>
            </Form.Group>

            {state?.number?.did_status === 'A' ? (
              <>
                {' '}
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Renew
                  </Form.Label>
                  {!renew ? (
                    <Button
                      variant="outline-light"
                      className="text-grey"
                      size="sm"
                      type="button"
                      onClick={() => setRenew(true)}
                    >
                      Renew
                    </Button>
                  ) : (
                    <RenewPage setRenew={setRenew} state={state} />
                  )}
                </Form.Group>
                <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                  <Form.Label className="w-100 font-15 font-600 text-grey">
                    Delete
                  </Form.Label>
                  <Button
                    variant="warning"
                    size="sm"
                    type="button"
                    onClick={() => handleDelete()}
                  >
                    {isLoading ? 'Deleting...' : 'Delete'}
                  </Button>
                </Form.Group>
              </>
            ) : (
              <div
                className="account__digit w-25 h-100 d-flex align-items-center justify-content-center m-auto
              "
              >
                Verification Pending....
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProfileDetail;
