import { StateProvider, reducer, initialState } from './context';

import ReactDOM, { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import './index.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
const stripePromise = loadStripe('pk_test_Pr0bvH0sR16ZmGK5ojYfi5aP');

const appearance = {
  theme: 'stripe',
  hidePostalCode: true,
};
const options = {
  appearance,
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <StateProvider initialState={initialState} reducer={reducer}>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      >
        <BrowserRouter>
          <Elements stripe={stripePromise} options={options}>
            <App />
          </Elements>
        </BrowserRouter>
      </ThemeProvider>
    </StateProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);
