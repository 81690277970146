import { serializeToQueryString } from '../helpers/misc';
import { get, getApiUrl, post, uploadPost } from './config';

const getCallHistory = (params) =>
  get({
    url: getApiUrl(`api/call-report${serializeToQueryString(params)}`),
  });
const getSmsHistory = (params) =>
  get({
    url: getApiUrl(`api/sms-report${serializeToQueryString(params)}`),
  });
const getTopUpList = () =>
  get({
    url: getApiUrl(`api/topup`),
  });
const getBundleDataList = (inputs) =>
  post({
    url: getApiUrl(`api/bundle`),
    payload: inputs,
  });
const purchaseBundle = (inputs) =>
  post({
    url: getApiUrl(`api/purchase-bundle`),
    payload: inputs,
  });
const sendMessage = (inputs) =>
  post({
    url: getApiUrl(`api/send-sms`),
    payload: inputs,
  });
const renewNumber = (inputs) =>
  post({
    url: getApiUrl(`api/did-number-renew`),
    payload: inputs,
  });
const deleteNumber = (inputs) =>
  post({
    url: getApiUrl(`api/did-number/delete`),
    payload: inputs,
  });
const getCredit = () =>
  get({
    url: getApiUrl(`api/check-balance`),
  });
const getProfile = () =>
  get({
    url: getApiUrl(`api/profile`),
  });
const getCountryList = () =>
  get({
    url: getApiUrl(`api/did-country-list`),
  });
const getFranceCityList = () =>
  get({
    url: getApiUrl(`api/france-city-list`),
  });
const getActiveBundle = () =>
  get({
    url: getApiUrl(`api/active-bundle`),
  });

const getPaymentHistory = (params) =>
  get({
    url: getApiUrl(`api/payment-report${serializeToQueryString(params)}`),
    payload: params,
  });
const getCityList = (params) =>
  get({
    url: getApiUrl(`api/did-city-list/${params}`),
  });
const getCityListUS = (params) =>
  get({
    url: getApiUrl(`api/did-state-city-list/${params}`),
  });
const getStateList = (params) =>
  get({
    url: getApiUrl(`api/did-state/${params}`),
  });
const getDIDDetails = (params) =>
  get({
    url: getApiUrl(`api/selected-did-details/${params}`),
  });
// const getDIDDetailsFrance = (params) =>
//   get({
//     url: getApiUrl(`api/available-did-details/${params}`),
//   });
const getDIDDetailsFrance = (params) =>
  get({
    url: getApiUrl(`api/france-did-details/${params}`),
  });
const getDidNumberList = (params) =>
  get({
    url: getApiUrl(`api/did-number-list/${params}`),
  });
const countryDidNumber = (params) =>
  get({
    url: getApiUrl(`api/country-did-number/${params}`),
  });
const getStateCities = (params) =>
  get({
    url: getApiUrl(`api/did-state-city-list/${params}`),
  });
// const getFranceDidNumberList = (params) =>
//   get({
//     url: getApiUrl(`api/did-numbers/${params}`),
//   });
const getFranceDidNumberList = () =>
  get({
    url: getApiUrl(`api/france-number-list`),
  });
const createDIDOrder = (inputs) =>
  uploadPost({
    url: getApiUrl(`api/did-create-order`),
    payload: inputs,
  });
const createDIDOrderFrance = (inputs) =>
  uploadPost({
    url: getApiUrl(`api/did-create-request`),
    payload: inputs,
  });
const updateProfile = (inputs) =>
  post({
    url: getApiUrl(`api/update-profile`),
    payload: inputs,
  });
const updateProfile1 = (inputs) =>
  post({
    url: getApiUrl(`api/update-setting`),
    payload: inputs,
  });
const paymentToken = (inputs) =>
  post({
    url: getApiUrl(`api/payment-token`),
    payload: inputs,
  });
const paymentTokenUpload = (inputs) =>
  uploadPost({
    url: getApiUrl(`api/payment-token`),
    payload: inputs,
  });
const UploadMedia = (inputs) => {
  return uploadPost({
    url: getApiUrl(`api/media-upload`),
    payload: inputs,
  });
};
const transferCredit = (inputs) =>
  post({
    url: getApiUrl(`api/transfer-credit`),
    payload: inputs,
  });

const changePassword = (inputs) => {
  post({
    url: getApiUrl(`api/reset-password-web`),
    payload: inputs,
  });
};

const historyService = {
  getCallHistory,
  getPaymentHistory,
  getTopUpList,
  getBundleDataList,
  purchaseBundle,
  getCredit,
  getProfile,
  getCountryList,
  getCityList,
  getDidNumberList,
  createDIDOrder,
  getDIDDetails,
  renewNumber,
  deleteNumber,
  getActiveBundle,
  updateProfile,
  paymentToken,
  transferCredit,
  updateProfile1,
  getFranceCityList,
  getFranceDidNumberList,
  getDIDDetailsFrance,
  createDIDOrderFrance,
  getStateList,
  getCityListUS,
  countryDidNumber,
  getStateCities,
  sendMessage,
  getSmsHistory,
  changePassword,
  paymentTokenUpload,
  UploadMedia,
};

export default historyService;

//
