import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useStateValue } from '../context';
import { actionTypes } from '../context/reducer';
import { useMutation, useQueryClient } from 'react-query';
import authService from '../services/authService';

const Paypal = () => {
  let user = localStorage?.userDetails
    ? JSON.parse(localStorage?.userDetails)
    : {};
  const [state, dispatch] = useStateValue();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    ['paypal-payment'],
    authService.stripPayment,
    {
      onSuccess: ({ message }) => {
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'success' },
        });
        queryClient.invalidateQueries('get-credit');
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  const handleOrderApprove = async (data, actions) => {
    const order = await actions.order.capture();
    const payload = {
      ...order,
      type: 'paypal',
      amount: location?.state?.amount ?? '',
    };
    await mutateAsync(payload);
  };
  const handleOrderError = async (error) => {
    console.error(error);
  };
  return (
    <div className="account text-center w-max-500 mx-auto">
      <Container>
        <PayPalScriptProvider
          options={{
            'client-id':
              'Ac3Ez1ltd1J_fMlmgyNvMvBU-daioRTDDhsrgvfj3UW7CP5C93csZNlEDvxoI3Aq-9QhEeVfXYvdjs0v',
            currency: 'USD',
          }}
        >
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                intent: 'CAPTURE',
                purchase_units: [
                  {
                    description: 'TOP UP',
                    custom_id: user?.missdn,
                    amount: {
                      value: `${location?.state?.amount ?? '00.00'}`,
                      currency_code: 'USD',
                    },
                  },
                ],
              });
            }}
            onApprove={handleOrderApprove}
            onError={handleOrderError}
          />
        </PayPalScriptProvider>
      </Container>
    </div>
  );
};

export default Paypal;
