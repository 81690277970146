import React, { useState } from 'react';
import { useEffect } from 'react';
import { ButtonGroup, Card, ToggleButton } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import swal from 'sweetalert';
import { useStateValue } from '../context';
import { actionTypes } from '../context/reducer';
import historyService from '../services/historyService';

const initialRadios = [
  { name: '1 Month', value: '1' },
  { name: '3 Month', value: '3' },
  { name: '12 Month', value: '12' },
];
const RenewPage = ({ setRenew, state }) => {
  const queryClient = useQueryClient();
  const [radios, setRadios] = useState(initialRadios);
  const [radioValue, setRadioValue] = useState(null);
  const [_, dispatch] = useStateValue();
  let user = localStorage?.userDetails
    ? JSON.parse(localStorage?.userDetails)
    : {};
  const { isLoading, mutateAsync } = useMutation(
    ['renewNumber'],
    historyService.renewNumber,
    {
      onSuccess: ({ message }) => {
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: {
            status: true,
            message: message,
            msgType: 'success',
          },
        });
        handleClose();
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  useEffect(() => {
    if (state?.data) {
      const radiosWithPrice = state?.data?.price?.map(
        ({ cost, currency, period }) => ({
          cost: `${cost}`,
          currency: `${currency}`,
          period: `${period}`,
        }),
      );
      setRadios(radiosWithPrice);
    }
  }, [state?.data]);

  function handleClose() {
    queryClient.invalidateQueries('getProfile');
    queryClient.invalidateQueries('get-credit');
    setRenew(false);
  }

  function handleCost() {
    return radios?.filter((data) => data?.period == radioValue)?.[0]?.cost;
  }

  async function handleRenew() {
    const payload = {
      uniquecode: state?.number?.uniquecode ?? '',
      period: Number(radioValue) || '',
      amount: handleCost() || '',
      type: 'Credit',
      currency: user?.currency ?? '',
    };
    swal({
      text: `${handleCost()} ${
        user?.currency
      } will be deducted from your current credit balance.`,
      buttons: true,
      confirmButtonColor: '#ffcc00',
    }).then(async (willBuy) => {
      if (willBuy) {
        await mutateAsync(payload);
      } else {
        return false;
      }
    });
  }

  return (
    <div className="Card_form">
      <ButtonGroup className="mb-2">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="outline-warning"
            name="radio"
            value={radio?.period}
            checked={radioValue === radio?.period}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {`${radio?.period} Month (${radio?.cost} ${radio?.currency})`}
          </ToggleButton>
        ))}
      </ButtonGroup>

      <div className="mt-3 d-flex gap-2">
        <button
          type="button"
          className="btn btn-black btn-sm"
          onClick={() => handleClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-warning btn-sm"
          onClick={() => handleRenew()}
        >
          {isLoading ? 'Please wait...' : 'Renew'}
        </button>
      </div>
    </div>
  );
};

export default RenewPage;
