import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { useStateValue } from '../context';
import { actionTypes } from '../context/reducer';
import historyService from '../services/historyService';
import { ReactComponent as Transfer } from '../images/transfer.svg?component';
const initialState = {
  to: '',
  amount: '',
};
const TransferCredit = () => {
  const [, dispatch] = useStateValue();
  const [initialValues, setinitialValues] = useState(initialState);

  let user = localStorage?.userDetails
    ? JSON.parse(localStorage?.userDetails)
    : {};

  const validationSchema = Yup.object().shape({
    to: Yup.string().required('This field is required'),
    amount: Yup.string().required('Amount is required'),
  });

  const { isLoading, mutateAsync } = useMutation(
    ['transferCredit'],
    historyService.transferCredit,
    {
      onSuccess: ({ message }) => {
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: {
            status: true,
            message: message,
            msgType: 'success',
          },
        });
        handleReset();
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  const handleSubmitForm = async (values) => {
    await mutateAsync({ ...values });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: handleSubmitForm,
  });

  function handleReset() {
    formik.resetForm();
  }

  return (
    <Card>
      <Card.Header className="p-3">
        <Transfer />
        Transfer Credit
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
            <Form.Label className="w-100 font-15 font-600 text-grey ">
              Amount
            </Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1ee">
                {user?.symbol}
              </InputGroup.Text>
              <Form.Control
                type="number"
                className="form-control"
                placeholder="Enter amount"
                aria-label="amount"
                name="amount"
                min={0}
                isInvalid={formik.touched.amount && formik.errors.amount}
                onChange={(e) =>
                  formik.setFieldValue('amount', Math.abs(e.target.value))
                }
                onBlur={formik.handleBlur}
                value={formik.values.amount}
              />
              <Form.Control.Feedback type="invalid">
                {formik.touched.amount && formik.errors.amount && (
                  <div className="error_required w-100 text-danger font-11 ">
                    {formik.errors.amount}
                  </div>
                )}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
            <Form.Label className="w-100 font-15 font-600 text-grey ">
              To
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className="form-control"
                placeholder="Intl. format e.g. 447700900123"
                aria-label="to"
                name="to"
                isInvalid={formik.touched.to && formik.errors.to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.to}
              />
              <Form.Control.Feedback type="invalid">
                {formik.touched.to && formik.errors.to && (
                  <div className="error_required w-100 text-danger font-11 ">
                    {formik.errors.to}
                  </div>
                )}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
            <Form.Label className="w-100 font-15 font-600 text-grey">
              &nbsp;
            </Form.Label>
            <Button
              variant="warning"
              size="sm"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Please wait...' : 'Transfer'}
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default TransferCredit;
