let user = localStorage.userDetails ? JSON.parse(localStorage.userDetails) : '';
let access = localStorage.access || '';
let refresh_token = localStorage.refresh_token || '';

export const actionTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_SHOW_TOAST: 'SET_SHOW_TOAST',
  SET_USER_LOGOUT: 'SET_USER_LOGOUT',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_USER: 'SET_USER',
  SET_SIGNUP_INPUTS: 'SET_SIGNUP_INPUTS',
  SET_FORGOT_PASSWORD_DATA: 'SET_FORGOT_PASSWORD_DATA',
  UPDATE_ACCESS_TOKEN: 'UPDATE_ACCESS_TOKEN',
};

export const initialState = {
  userDetails: '' || user,
  access: '' || access,
  showToast: { status: false, message: '', msgType: 'default' },
  signupInputs: null,
  forgotPasswordData: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      localStorage.setItem('access', action?.value?.access_token);
      localStorage.setItem('refresh_token', action?.value?.refresh_token);
      return {
        ...state,
        access: action.value.access_token,
        refresh_token: action.value.refresh_token,
      };

    case actionTypes.UPDATE_ACCESS_TOKEN:
      localStorage.setItem('access', action?.value);
      return {
        ...state,
        access: action.value.access_token,
      };

    case actionTypes.SET_SIGNUP_INPUTS:
      return { ...state, signupInputs: action.value };

    case actionTypes.SET_USER_DATA:
      if (action.value)
        localStorage.setItem('userDetails', JSON.stringify(action.value));
      return { ...state, access: action.value };

    case actionTypes.SET_USER:
      if (action.value)
        localStorage.setItem('userDetails', JSON.stringify(action.value));
      return { ...state, userDetails: action.value };

    case actionTypes.SET_USER_LOGOUT:
      localStorage.clear();
      return { ...state, access: action.value, refresh_token: '' };

    case actionTypes.SET_SHOW_TOAST:
      return { ...state, showToast: action.value };

    case actionTypes.SET_FORGOT_PASSWORD_DATA:
      return { ...state, forgotPasswordData: action.value };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
