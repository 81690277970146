import { useFormik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { Button, Card, Form, FloatingLabel } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useStateValue } from '../context';
import { actionTypes } from '../context/reducer';
import * as yup from 'yup';
import historyService from '../services/historyService';
import SmsCounter from 'sms-counter';
import { useEffect } from 'react';

const initialValues = {
  from: '',
  to: '',
  text: '',
};

const SmsForm = () => {
  const [state, dispatch] = useStateValue();
  const [didNumbers, setDidNumbers] = useState([]);
  const [smsDetails, setSmsDetails] = useState({
    encoding: '',
    length: 0,
    messages: 0,
    per_message: '',
    remaining: '',
  });

  console.log('smsDetails', smsDetails);

  const { data, isError, isFetching } = useQuery(
    ['getProfile'],
    historyService.getProfile,
    {
      onSuccess: ({ data }) => {
        if (data) {
          setDidNumbers(data?.did_numbers ?? []);
        }
      },
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  const { isLoading, mutateAsync } = useMutation(
    ['sendMessage'],
    historyService.sendMessage,
    {
      onSuccess: ({ message }) => {
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: {
            status: true,
            message: message,
            msgType: 'success',
          },
        });
        formik?.setFieldValue('text', '');
      },
      onError: (error) => {
        
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        if (error.response?.status === 502) {
          let msg =
            'SMS failed to send due to slow internet connection. Please try again.';
          dispatch({
            type: actionTypes.SET_SHOW_TOAST,
            value: { status: true, message:msg, msgType: 'danger' },
          });
        } else {
          dispatch({
            type: actionTypes.SET_SHOW_TOAST,
            value: { status: true, message, msgType: 'danger' },
          });
        }
      },
    },
  );

  const validationSchema = yup.object().shape({
    from: yup.string().required('This is required'),
    to: yup.string().required('This is required'),
    text: yup.string().required('This is required'),
  });

  const handleSubmitForm = async (values) => {
    await mutateAsync(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmitForm,
  });

  useEffect(() => {
    const result = SmsCounter.count(formik?.values?.text);
    setSmsDetails(result);
  }, [formik?.values?.text]);

  return (
    <Card>
      <Card.Header className="p-3">Send SMS</Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
            <Form.Label className="w-100 font-15 font-600 text-grey">
              From
            </Form.Label>
            <div className="d-flex flex-column w-100">
              <Form.Select
                className="form-control"
                name="from"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.from}
              >
                <option value="">Please select number</option>
                {didNumbers &&
                  didNumbers.length > 0 &&
                  didNumbers.map(({ did_number }, index) => (
                    <option key={index} value={did_number}>
                      {did_number}
                    </option>
                  ))}
              </Form.Select>
              {formik.touched.from && formik.errors.from && (
                <div className="error_required w-100 text-danger font-11 ">
                  {formik.errors.from}
                </div>
              )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
            <Form.Label className="w-100 font-15 font-600 text-grey">
              To
            </Form.Label>
            <div className="d-flex flex-column w-100">
              <Form.Control
                className="form-control"
                placeholder="Enter number"
                aria-label="to"
                name="to"
                type="text"
                onChange={(e) =>
                  formik.setFieldValue(
                    'to',
                    e.target.value
                      .toString()
                      .replace(/[^+0-9*#]/g, '')
                      .slice(0, 15),
                  )
                }
                onBlur={formik.handleBlur}
                value={formik.values.to}
              />
              {formik.touched.to && formik.errors.to && (
                <div className="error_required w-100 text-danger font-11 ">
                  {formik.errors.to}
                </div>
              )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
            <Form.Label className="w-100 font-15 font-600 text-grey ">
              Message
            </Form.Label>
            <Card className="w-100">
              <Card.Body>
                <Form.Control
                  as="textarea"
                  placeholder="Please enter message"
                  style={{ height: '100px' }}
                  name="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.text}
                  // onClick={() => handleText()}
                />
              </Card.Body>

              <Card.Header className="p-3">
                Characters: {smsDetails?.length} Parts: {smsDetails?.messages}
              </Card.Header>
            </Card>
          </Form.Group>

          <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
            <Form.Label className="w-100 font-15 font-600 text-grey">
              &nbsp;
            </Form.Label>
            <Button variant="warning" size="sm" type="submit">
              {isLoading ? 'Sending...' : 'Send'}
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default SmsForm;
