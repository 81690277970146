import {
  Container,
  Nav,
  Navbar,
  // NavDropdown,
  Image,
  Button,
} from 'react-bootstrap';

import Logo from '../../images/logo.png';
import { ReactComponent as UserIcon } from '../../images/user.svg';
// import USA from '../../images/img_usa.png';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useStateValue } from '../../context';
import { actionTypes } from '../../context/reducer';
import historyService from '../../services/historyService';
// import Loader from '../common/Loader';
import { useQuery } from 'react-query';

const AppNavbar = () => {
  const [{ access }] = useStateValue();

  return (
    <Container>
      <Navbar expand="lg">
        <div onClick={() => (window.location.href = 'https://www.zeyi.co/en/')}>
          <Image
            src={Logo}
            alt="Logo"
            className="img-logo"
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          {!access && <OuterRoutes />}
          {access && <InnerRoutes />}
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

const InnerRoutes = () => {
  const [_, dispatch] = useStateValue();
  let user = localStorage?.userDetails
    ? JSON.parse(localStorage?.userDetails)
    : {};
  const navigate = useNavigate();

  const { isLoading, data: creditData } = useQuery(
    ['get-credit'],
    () => historyService.getCredit(),
    {
      onError: (error) => {
        let message = error.message;
        if (error.response?.data) message = error.response.data.message;
        dispatch({
          type: actionTypes.SET_SHOW_TOAST,
          value: { status: true, message, msgType: 'danger' },
        });
      },
    },
  );

  const handleLogout = () => () => {
    dispatch({ type: actionTypes.SET_USER_LOGOUT, value: '' });
    navigate('/');
  };

  return (
    <Nav
      className="me-auto my-2 my-lg-0 w-100 d-flex align-items-center justify-content-between"
      style={{ maxHeight: '100px' }}
      navbarScroll
    >
      <Nav>
        <Nav.Link as={NavLink} to="/account" className="nav-text text-dark">
          Account
        </Nav.Link>
        <Nav.Link as={NavLink} to="/setting" className="nav-text text-dark">
          Settings
        </Nav.Link>
        <Nav.Link as={NavLink} to="/profile" className="nav-text text-dark">
          Profiles
        </Nav.Link>
        <Nav.Link as={NavLink} to="/history" className="nav-text text-dark">
          History
        </Nav.Link>
        <Nav.Link as={NavLink} to="/action" className="nav-text text-dark">
          Actions
        </Nav.Link>
      </Nav>
      {/* <div className="right_nav d-flex align-items-center">
        <LinkContainer to="/">
          <Button variant="dark" className="uppercase font-13 me-1 font-600 btn-small">
            Login
          </Button>
        </LinkContainer>
        <LinkContainer to="signup">
          <Button variant="warning" className="uppercase font-13 me-2 font-600 latter-spacing btn-small">
            Create your account
          </Button>
        </LinkContainer>
        <div className="d-flex align-items-center">
          <img src={USA} className="flex_img" alt="flag" />
          <Navbar.Collapse id="navbar-dark-example">
            <Nav>
              <NavDropdown id="nav-dropdown-dark-example" title="EN" menuVariant="light" className="no-border">
                <NavDropdown.Item href="#action/3.1" className="py-0 px-2 no-border">
                  FR
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </div> */}
      <div className="right_nav d-flex align-items-center">
        {/* <LinkContainer to="#"> */}
        {isLoading ? (
          <p>Loading..</p>
        ) : (
          <Button
            variant="warning"
            type="button"
            className="uppercase font-13 me-2 font-600 latter-spacing btn-small"
          >
            {`${creditData?.data?.symbol || user?.currency} ${
              creditData?.data?.credit || user?.credit || '00.00'
            }`}
          </Button>
        )}
        {user?.name ?? ''}|{/* </LinkContainer> */}
        <Nav.Link
          as={'span'}
          onClick={handleLogout()}
          className="btn nav-text text-dark d-flex align-items-center"
        >
          <span className="userIcon d-flex justify-content-center align-items-center me-1">
            <UserIcon />
          </span>{' '}
          Logout
        </Nav.Link>
      </div>
    </Nav>
  );
};

const OuterRoutes = () => {
  return (
    <Nav
      className="me-auto my-2 my-lg-0 w-100 d-flex align-items-center justify-content-between"
      style={{ maxHeight: '100px' }}
      navbarScroll
    >
      <Nav>
        {/* <Nav.Link as={NavLink} to="/about" className="nav-text text-dark">
          About Zeyi
        </Nav.Link>
        <Nav.Link as={NavLink} to="/features" className="nav-text text-dark">
          Features
        </Nav.Link>
        <Nav.Link as={NavLink} to="rate" className="nav-text text-dark">
          Rates
        </Nav.Link>
        <Nav.Link as={NavLink} to="faq" className="nav-text text-dark">
          Faq
        </Nav.Link> */}
        {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
            </NavDropdown> */}
      </Nav>
      <div className="right_nav d-flex align-items-center">
        <LinkContainer to="/">
          <Button
            variant="dark"
            className="uppercase font-13 me-1 font-600 btn-small"
          >
            Login
          </Button>
        </LinkContainer>
        <LinkContainer to="signup">
          <Button
            variant="warning"
            className="uppercase font-13 me-2 font-600 latter-spacing btn-small"
          >
            Create your account
          </Button>
        </LinkContainer>
        {/* <div className="d-flex align-items-center">
          <img src={USA} className="flex_img" alt="flag" />
          <Navbar.Collapse id="navbar-dark-example">
            <Nav>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title="EN"
                menuVariant="light"
                className="no-border"
              >
                <NavDropdown.Item
                  href="#action/3.1"
                  className="py-0 px-2 no-border"
                >
                  FR
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div> */}
      </div>
    </Nav>
  );
};

export default AppNavbar;
