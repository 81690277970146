import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Error404 from '../../images/error404.png';

const ErrorPage = () => {
  return (
    <>
      <div className="errorPage vh-100 vw-100 d-flex align-items-center justify-content-center">
        <div className="w-100 d-flex flex-column w-max-600 align-items-center gap-1">
          <Image src={Error404} alt="Error404" />
          <h4>Page Not Found</h4>
          <Button
            as={Link}
            to="/"
            variant="warning"
            size="md"
            className="uppercase font-13 me-1 font-600 btn-small"
          >
            Go Home
          </Button>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
