import { lazy } from 'react';

export const AboutPage = lazy(() => import('./AboutPage'));
export const RatePage = lazy(() => import('./RatePage'));
export const FeaturesPage = lazy(() => import('./FeaturesPage'));
export const FaqPage = lazy(() => import('./FaqPage'));
export const FaqInnerPage = lazy(() => import('./FaqInnerPage'));
export const FaqHowItWork = lazy(() => import('./FaqHowItWork'));
export const FaqTechnicalPage = lazy(() => import('./FaqTechnicalPage'));
export const FaqAccount = lazy(() => import('./FaqAccount'));
export const FaqCalling = lazy(() => import('./FaqCalling'));
export const FaqMessage = lazy(() => import('./FaqMessage'));
export const FaqBilling = lazy(() => import('./FaqBilling'));
export const FaqNumbers = lazy(() => import('./FaqNumbers'));
export const FaqDownloads = lazy(() => import('./FaqDownloads'));
export const PrivacyAndPolicy = lazy(() => import('./PrivacyAndPolicy'));
export const CookiesPage = lazy(() => import('./CookiesPage'));
export const TermsPage = lazy(() => import('./TermsPage'));
export const ContactPage = lazy(() => import('./ContactPage'));
export const AccountPage = lazy(() => import('./AccountPage'));
export const TopupPage = lazy(() => import('./Topup'));
export const ActionPage = lazy(() => import('./ActionPage'));
export const ResetPasswordPage = lazy(() => import('./ResetPasswordPage'));
export const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage'));
export const HomePage = lazy(() => import('./HomePage'));
export const LoginPage = lazy(() => import('./LoginPage'));
export const OtpPage = lazy(() => import('./OtpPage'));
export const ProfilePage = lazy(() => import('./ProfilePage'));
export const SignupPage = lazy(() => import('./SignupPage'));
export const HistoryPage = lazy(() => import('./HistoryPage'));
export const SettingPage = lazy(() => import('./SettingPage'));
export const AddMinuteBundle = lazy(() => import('./AddMinuteBundle'));
export const AddSmsBundle = lazy(() => import('./AddSmsBundle'));
